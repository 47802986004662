import { useApi } from '@backstage/core-plugin-api';
import { userSettingsApiRef } from '../api-refs';
import { useSidebarPinState } from '@backstage/core-components';
import { useEffect, useState } from 'react';
import { USER_PREFERENCES } from '@mercedes-benz/user-settings-common';

export interface PreferenceValue {
  isPinned: boolean;
}

export function useSidebarPreferences(feature: string) {
  const userSettingsApi = useApi(userSettingsApiRef);
  const { isPinned } = useSidebarPinState();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const updateSidebarPreference = async () => {
      const preference = await userSettingsApi.get({
        bucket: USER_PREFERENCES,
        key: feature,
      });

      const value = preference?.value as unknown as PreferenceValue;

      if (!value) {
        await userSettingsApi.set({
          bucket: USER_PREFERENCES,
          key: feature,
          value: {
            isPinned: isPinned,
          },
        });
      }

      setIsInitialized(true);
    };

    updateSidebarPreference();
  }, [userSettingsApi, isPinned, feature]);

  return { isInitialized };
}
