import { useApi } from '@backstage/core-plugin-api';
import { useCallback, useEffect } from 'react';
import { userSettingsApiRef } from '@mercedes-benz/user-settings-react';
import {
  FEATURE_ACADEMY_PROGRESS,
  USER_PREFERENCES,
} from '@mercedes-benz/user-settings-common';
import { AcademyEntity } from '@mb.io/catalog-model';

export function useProgressEventListeners(entity: AcademyEntity) {
  const userSettingsApi = useApi(userSettingsApiRef);

  const trackUserProgress = useCallback(async () => {
    const setPreferences = (isDone?: boolean) => {
      setTimeout(async () => {
        const hash = window.location.hash;
        const uniqueKey = `${FEATURE_ACADEMY_PROGRESS}_${entity.metadata.name?.replace(
          / /g,
          '_',
        )}`;

        await userSettingsApi.set({
          bucket: USER_PREFERENCES,
          key: uniqueKey,
          value: isDone ? 'done' : hash,
        });
      }, 0); // Add delay to ensure the hash is updated
    };

    const addEventListenerToStep = (step: any, isDone?: boolean) => {
      step.addEventListener('click', async () => {
        setPreferences(isDone);
      });
    };

    const nextButton = document.getElementById('next-step');
    if (nextButton) {
      addEventListenerToStep(nextButton);
    }

    const backButton = document.getElementById('previous-step');
    if (backButton) {
      addEventListenerToStep(backButton);
    }

    const doneButton = document.getElementById('done');
    if (doneButton) {
      addEventListenerToStep(doneButton, true);
    }

    const stepTitles = document.querySelectorAll('.step');
    stepTitles.forEach(step => {
      const span = step.querySelector('span');
      if (span) {
        addEventListenerToStep(span);
      }
    });
  }, [entity, userSettingsApi]);

  useEffect(() => {
    trackUserProgress();
  }, [trackUserProgress]);

  return { trackUserProgress: trackUserProgress };
}
