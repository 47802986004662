import { createApp } from '@backstage/app-defaults';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import {
  AppRouter,
  FlatRoutes,
  SignInPageProps,
} from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import {
  configApiRef,
  discoveryApiRef,
  githubAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import { BazaarPage } from '@backstage-community/plugin-bazaar';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogGraphPage,
  catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { explorePlugin } from '@backstage-community/plugin-explore';
import { orgPlugin } from '@backstage/plugin-org';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import {
  ExpandableNavigation,
  LightBox,
  ReportIssue,
  TextSize,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  mbuiDarkThemeWithProvider,
  mbuiLightThemeWithProvider,
  useErrorPageStyle,
  useSearchPageStyle,
} from '@mb.io/core-components';
import { HomePage, homePlugin } from '@mercedes-benz/home';
import {
  PlatformFeatureFlagContextProvider,
  PlatformFeatureFlagged,
  PlatformFeatureFlagPage,
  UserFeatureFlagContextProvider,
} from '@mb.io/plugin-platform-feature-flag';
import { SechubPage } from '@mercedes-benz/backstage-plugin-sechub';
import {
  EsgCategoryPage,
  EsgHomePage,
  EsgReaderPage,
  EsgStaticComponentPage,
} from '@mercedes-benz/ecosystem-guidelines';
import {
  CatalogAnalyzerPage,
  catalogAnalyzerPlugin,
} from '@mercedes-benz/plugin-catalog-analyzer';
import { DatadogRum } from '@mercedes-benz/plugin-datadog-rum';
import { SecCompPage } from '@mercedes-benz/plugin-seccomp-dashboard';
import { TdmsNextPage } from '@mercedes-benz/plugin-tdms-next';
import { PmasPage } from '@mercedes-benz/pmas';
import { SipCentralPage } from '@mercedes-benz/sip-central';
import { SettingsPage } from '@mercedes-benz/user-settings';
import React, { PropsWithChildren, useState } from 'react';
import { Navigate, Route } from 'react-router';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { apis, oidcAuthRef } from './apis';
import { Root } from './components/Root';
import { EntityPage } from './components/catalog/EntityPage';
import { customDevToolsPage } from './components/dev-tools/CustomDevToolsPage';
import { FetchInterceptorOutlet } from './components/fetch/FetchInterceptorOutlet';
import { ExplorePage } from './components/overlays/explore/ExplorePage';
import { SeamlessIndexPage } from './components/seamless/explorer/SeamlessIndexPage';
import { searchPage } from './components/search/SearchPage';
import { techDocsPage } from './components/techdocs-wrapper/TechDocsPage';
import {
  AlphaNumericValidation,
  CatalogEntityNameValidation,
  JavaPackageNameValidation,
  MarkdownField,
  MavenArtifactIdValidation,
  MavenGroupIdValidation,
  NpmArtifactNameValidation,
  NpmScopeValidation,
  SeamlessPrefixValidation,
} from './extensions/extensions';
import './fonts/typeface-mb-corpo-a/index.css';
import './fonts/typeface-mb-corpo-s/index.css';
import * as plugins from './plugins';
import {
  NextScaffolderPage,
  scaffolderPlugin,
} from '@mercedes-benz/scaffolder-next';
import { PostLoginManager } from './components/fetch/PostLoginManager';
import { ICONS } from './icons';
import { AliceErrorPage } from './alice-error.page';
import { AcademyCatalog } from '@mercedes-benz/academy';
import { DwsPage } from '@mercedes-benz/plugin-dws';
import { Mermaid } from '@mercedes-benz/backstage-plugin-techdocs-addon-mermaid';
import { TdmsNewPage } from '@mercedes-benz/plugin-tdms-new';
import { CatalogHomePage } from '@mercedes-benz/plugin-catalog-home';
import { SynchronizeTheme } from './components/synchronize-theme/synchronize-theme';

function createSignInComponent() {
  return {
    SignInPage(props: PropsWithChildren<SignInPageProps>) {
      const config = useApi(configApiRef);
      const discoveryApi = useApi(discoveryApiRef);
      const providers: Array<'guest' | SignInProviderConfig> = [];

      const authEnabled = config.getOptionalBoolean('app.auth.enabled');
      const authApi = useApi(oidcAuthRef);
      const origin = authApi.getBackstageIdentity;

      const [hasAliceError, setAliceError] = useState(false);

      // overriding this function helps us to get exceptions from backend after calling oidc.
      authApi.getBackstageIdentity = async options => {
        try {
          return await origin.apply(authApi, [options]);
        } catch (e) {
          if (
            e instanceof Error &&
            e.message?.toLowerCase().includes('alice')
          ) {
            setAliceError(true);
          }
          throw new Error('Login failed, invalid role.');
        }
      };
      // explicit false check, if undefined or empty (not configured) or true then the authentication is enabled.
      if (authEnabled === false) {
        // if authentication not provided, enable guest and GitHub provider.
        providers.push('guest');
      } else {
        const enabledProviders: ('oidc' | 'github' | 'guest')[] =
          config.get('app.auth.providers');

        if (enabledProviders.includes('oidc')) {
          providers.push({
            id: 'oidc-auth-provider',
            title: 'GAS OIDC',
            message: 'Sign in using SSO',
            apiRef: oidcAuthRef,
          });
        }

        if (enabledProviders.includes('github')) {
          providers.push({
            id: 'github-auth-provider',
            title: 'GitHub',
            message: 'Sign in using GitHub SSO',
            apiRef: githubAuthApiRef,
          });
        }
        if (enabledProviders.includes('guest')) {
          providers.push('guest');
        }
      }

      return (
        <>
          <SignInPage
            {...props}
            align="center"
            auto
            providers={providers}
            onSignInSuccess={async identityApi => {
              const url = await discoveryApi.getBaseUrl('auth');
              const manager = new PostLoginManager(identityApi);
              await manager.setTokenCookie(`${url}/session/post-login`);
              props.onSignInSuccess(identityApi);
            }}
          />
          {hasAliceError && <AliceErrorPage />}
        </>
      );
    },
  };
}

const app = createApp({
  apis,
  themes: [mbuiDarkThemeWithProvider, mbuiLightThemeWithProvider],
  plugins: Object.values(plugins),
  icons: ICONS,
  components: {
    SignInPage: createSignInComponent().SignInPage,
    Router: props => {
      const router = createBrowserRouter([
        { path: '*', Component: () => <>{props.children}</> },
      ]);

      return <RouterProvider router={router} />;
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogAnalyzerPlugin.routes.root,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(explorePlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogAnalyzerPlugin.routes.root,
    });
    bind(homePlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
      catalogEntityRoute: catalogPlugin.routes.catalogEntity,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="/home" />} />
    <Route path="/home" element={<HomePage />} />
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route path="/seamless" element={<SeamlessIndexPage />} />
    <Route
      path="/platform-feature-flag"
      element={<PlatformFeatureFlagPage />}
    />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {EntityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ExpandableNavigation />
        <ReportIssue />
        <TextSize />
        <LightBox />
        <Mermaid
          config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
        />
      </TechDocsAddons>
      {techDocsPage}
    </Route>
    <Route path="/create" element={<NextScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <AlphaNumericValidation />
        <CatalogEntityNameValidation />
        <JavaPackageNameValidation />
        <MavenArtifactIdValidation />
        <MavenGroupIdValidation />
        <NpmArtifactNameValidation />
        <NpmScopeValidation />
        <SeamlessPrefixValidation />
        <MarkdownField />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/esg" element={<EsgHomePage />} />
    <Route path="/esg/category/:categoryId" element={<EsgCategoryPage />} />
    <Route
      path="/esg/static/:componentName/:categoryName"
      element={<EsgStaticComponentPage />}
    >
      <TechDocsAddons>
        <ExpandableNavigation />
        <ReportIssue />
        <TextSize />
        <LightBox />
        <Mermaid
          config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
        />
      </TechDocsAddons>
    </Route>
    <Route path="/esg/:namespace/:kind/:name/*" element={<EsgReaderPage />}>
      <TechDocsAddons>
        <ExpandableNavigation />
        <ReportIssue />
        <TextSize />
        <LightBox />
        <Mermaid
          config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
        />
      </TechDocsAddons>
    </Route>
    <Route path="/explore" element={<ExplorePage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/catalog-import" element={<CatalogAnalyzerPage />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/bazaar" element={<BazaarPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            selectedKinds: [
              'component',
              'domain',
              'system',
              'api',
              'group',
              'resource',
              'content',
              'seamless',
              'infrastructure',
              'kubernetes',
            ],
            maxDepth: 1,
            selectedRelations: [
              RELATION_OWNER_OF,
              RELATION_OWNED_BY,
              RELATION_CONSUMES_API,
              RELATION_API_CONSUMED_BY,
              RELATION_PROVIDES_API,
              RELATION_API_PROVIDED_BY,
              RELATION_HAS_PART,
              RELATION_PART_OF,
              RELATION_DEPENDS_ON,
              RELATION_DEPENDENCY_OF,
            ],
          }}
        />
      }
    />
    <Route path="/seccomp" element={<SecCompPage />} />
    <Route path="/user-settings" element={<SettingsPage />} />

    <Route path="/pmas" element={<PmasPage />} />
    <Route path="/sip-central" element={<SipCentralPage />} />
    <Route path="/catalog-analyzer" element={<CatalogAnalyzerPage />} />

    <Route
      path="/tdms-next"
      element={
        <PlatformFeatureFlagged
          feature="tdms-next"
          children={<TdmsNextPage />}
        />
      }
    />

    <Route
      path="/tdms-new"
      element={
        <PlatformFeatureFlagged feature="tdmsNew" children={<TdmsNewPage />} />
      }
    />

    <Route path="/sechub" element={<SechubPage />} />

    <Route
      path="/devtools"
      element={
        <RequirePermission
          permission={devToolsAdministerPermission}
          errorPage={<></>}
        >
          {customDevToolsPage}
        </RequirePermission>
      }
    />

    <Route
      path="/catalog-unprocessed-entities"
      element={<CatalogUnprocessedEntitiesPage />}
    />
    <Route path="/academy" element={<AcademyCatalog />} />

    <Route path="/dws" element={<DwsPage />} />

    <Route
      path="/catalog-home"
      element={
        <PlatformFeatureFlagged
          feature="catalogHome"
          children={<CatalogHomePage />}
        />
      }
    />
  </FlatRoutes>
);

const AppProvider = app.createRoot(
  <>
    <FetchInterceptorOutlet />
    <SynchronizeTheme />
    <AlertDisplay />
    <OAuthRequestDialog />
    <UserFeatureFlagContextProvider>
      <PlatformFeatureFlagContextProvider>
        <DatadogRum>
          <AppRouter>
            <Root>{routes}</Root>
          </AppRouter>
        </DatadogRum>
      </PlatformFeatureFlagContextProvider>
    </UserFeatureFlagContextProvider>
  </>,
);

const App = () => {
  useErrorPageStyle();
  useSearchPageStyle();
  return <AppProvider />;
};

export default App;
