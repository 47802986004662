import { useApi } from '@backstage/core-plugin-api';
import { userSettingsApiRef } from '../api-refs';
import { useSidebarPinState } from '@backstage/core-components';
import { useEffect, useRef } from 'react';
import { PreferenceValue } from './useSidebarPreferences';
import { USER_PREFERENCES } from '@mercedes-benz/user-settings-common';

export function useSyncSidebarPinState(
  isInitialized: boolean,
  feature: string,
) {
  const userSettingsApi = useApi(userSettingsApiRef);
  const { isPinned, toggleSidebarPinState } = useSidebarPinState();
  const initialSyncRef = useRef(true);

  useEffect(() => {
    if (!isInitialized) return;

    const syncSidebarPinState = async () => {
      const preference = await userSettingsApi.get({
        bucket: USER_PREFERENCES,
        key: feature,
      });

      const value = preference?.value as unknown as PreferenceValue;

      if (initialSyncRef.current) {
        initialSyncRef.current = false;
        if (value && value.isPinned !== isPinned) {
          toggleSidebarPinState();
        }
      } else if (value && value.isPinned !== isPinned) {
        await userSettingsApi.set({
          bucket: USER_PREFERENCES,
          key: feature,
          value: {
            isPinned: isPinned,
          },
        });
      }
    };

    syncSidebarPinState();
  }, [
    userSettingsApi,
    isPinned,
    toggleSidebarPinState,
    isInitialized,
    feature,
  ]);

  return { isPinned, toggleSidebarPinState };
}
