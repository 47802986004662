import { InfoCard } from '@backstage/core-components';
import Typography from '@mui/material/Typography';
import React from 'react';
import Divider from '@mui/material/Divider';

/**
 * Props for {@link AnalyzeInfoCard}.
 *
 * @public
 */
export interface AnalyzeInfoCardProps {
  exampleSimpleLocationUrl?: string;
  exampleRootLocationUrl?: string;
  exampleGlobUrl?: string;
}

/**
 * Shows information about the analyze process.
 *
 * @public
 */
export const AnalyzeInfoCard = (props: AnalyzeInfoCardProps) => {
  const {
    exampleSimpleLocationUrl = 'https://github.com/backstage/backstage/blob/master/catalog-info.yaml',
    exampleRootLocationUrl = 'https://git.i.mercedes-benz.com/dh-io-techdocs/nebula-platform/blob/main/catalog-root.yaml',
    exampleGlobUrl = 'https://git.i.mercedes-benz.com/dh-io-backstage/software-templates/tree/develop/academy-templates/academy-*.yaml',
  } = props;

  return (
    <InfoCard
      title="Analyze an existing component"
      titleTypographyProps={{ component: 'h3' }}
    >
      <Typography variant="body2" color="error" paragraph>
        We do not allow registering new components but you can still use this
        page to analyze and validate your entities.
      </Typography>
      <Typography variant="body2" paragraph>
        Enter the URL to your catalog file to analyze it.{' '}
        <b>
          <i>
            You can use glob patterns (*) to analyse an entire location for
            files matching the pattern.
          </i>
        </b>
      </Typography>
      <br />
      <Divider />
      <Typography component="h3" variant="h6">
        Examples:
      </Typography>
      <br />

      <Typography component="h4" variant="h6">
        Link to an existing entity file
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" paragraph>
        Example: <code>{exampleSimpleLocationUrl}</code>
      </Typography>
      <Typography variant="body2" paragraph>
        The wizard analyzes the catalog file location and previews the entities
      </Typography>
      <Typography component="h4" variant="h6">
        Link to existing entities through a root location file
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" paragraph>
        Example: <code>{exampleRootLocationUrl}</code>
      </Typography>
      <Typography variant="body2" paragraph>
        The wizard discovers all catalog files referenced by the root location,
        previews the entities, and gives analysing results
      </Typography>

      <Typography component="h4" variant="h6">
        Link to existing entities through a glob pattern url
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" paragraph>
        Example: <code>{exampleGlobUrl}</code>
      </Typography>
      <Typography variant="body2" paragraph>
        The wizard discovers all catalog files located in the GitHub repo folder
        matching specified glob pattern
      </Typography>
    </InfoCard>
  );
};
