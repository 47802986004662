import { CatalogApi } from '@backstage/catalog-client';
import {
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar-common';
import { TechRadarApi } from '@backstage-community/plugin-tech-radar';
import { ContentEntity } from '@mb.io/catalog-model';
import converter from 'csvtojson';

const colors = ['#46991f', '#0c3f9e', '#fbdb84', '#efafa9'];

export class TechRadarCustomClient implements TechRadarApi {
  constructor(private readonly catalogApi: CatalogApi) {}

  async load(): Promise<TechRadarLoaderResponse> {
    const entity: ContentEntity = (await this.catalogApi.getEntityByRef({
      kind: 'Content',
      namespace: 'default',
      name: 'techradar-data',
    })) as ContentEntity;

    if (!entity) {
      throw new Error(`Entity for tech radar not found! Added to discovery?`);
    }

    const { data } = entity.spec;
    if (!data) {
      throw new Error(`Tech radar data is empty!`);
    }

    const jsonPayload = await converter().fromString(data);
    const response: TechRadarLoaderResponse = {
      rings: [],
      quadrants: [],
      entries: [],
    };

    jsonPayload.forEach((item: any, index: number) => {
      TechRadarCustomClient.mapRings(item, index, response.rings);
      TechRadarCustomClient.mapQuadrants(item, response.quadrants);
      TechRadarCustomClient.pushEntry(item, response.entries);
    });
    return response;
  }

  private static mapRings({ ring }: any, index: number, rings: RadarRing[]) {
    if (rings.findIndex(current => current.name === ring) === -1) {
      rings.push({
        id: ring.toLowerCase(),
        name: ring,
        color: colors[index % colors.length],
      });
    }
  }

  private static mapQuadrants({ quadrant }: any, quadrants: RadarQuadrant[]) {
    if (quadrants.findIndex(current => current.name === quadrant) === -1) {
      quadrants.push({
        id: quadrant.toLowerCase(),
        name: quadrant,
      });
    }
  }

  private static pushEntry(
    { name, quadrant, description, ring }: any,
    entries: RadarEntry[],
  ) {
    entries.push({
      title: name,
      id: name.toLowerCase(),
      quadrant: quadrant.toLowerCase(),
      key: name.toLowerCase(),
      description: description,
      links: [{ url: '', title: name }],
      timeline: [
        {
          ringId: ring.toLowerCase(),
          date: new Date(),
        },
      ],
    });
  }
}
