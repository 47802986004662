import React, { useEffect } from 'react';
import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';
import { userSettingsApiRef } from '@mercedes-benz/user-settings-react';
import {
  FEATURE_THEME,
  USER_PREFERENCES,
} from '@mercedes-benz/user-settings-common';

export const SynchronizeTheme = () => {
  const userSettingsApi = useApi(userSettingsApiRef);
  const appThemeApi = useApi(appThemeApiRef);

  useEffect(() => {
    const updateTheme = async () => {
      const theme = await userSettingsApi.get({
        bucket: USER_PREFERENCES,
        key: FEATURE_THEME,
      });

      if (theme) {
        appThemeApi.setActiveThemeId(theme.value as string);
      } else {
        const activeThemeId = appThemeApi.getActiveThemeId();
        if (activeThemeId) {
          userSettingsApi.set({
            bucket: USER_PREFERENCES,
            key: FEATURE_THEME,
            value: activeThemeId,
          });
        }
      }
    };

    const subscription = appThemeApi.activeThemeId$().subscribe(newThemeId => {
      if (newThemeId) {
        userSettingsApi.set({
          bucket: USER_PREFERENCES,
          key: FEATURE_THEME,
          value: newThemeId,
        });
      } else {
        userSettingsApi.delete({
          bucket: USER_PREFERENCES,
          key: FEATURE_THEME,
        });
      }
    });

    updateTheme();

    return () => {
      subscription.unsubscribe();
    };
  }, [userSettingsApi, appThemeApi]);

  return <></>;
};
