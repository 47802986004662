import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import { Entity } from '@backstage/catalog-model';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import Chip from '@mui/material/Chip';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
  EntityRefLink,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';

export interface TableData {
  name: string;
  owner: string;
  description?: string;
  type?: string;
  tags?: string[];
  targets?: string;
}

export type EntityTableProps = {
  entities: Entity[];
  totalItems: number;
  headCells: HeadCell[];
};

const allowedRowsPerPage = [5, 10, 20, 50];
type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] === undefined) return -1;
  if (a[orderBy] === undefined) return 1;
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export interface HeadCell {
  id: keyof TableData;
  label: string;
}

interface EnhancedTableProps {
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof TableData,
  ) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function EnhancedTableHead(props: Readonly<EnhancedTableProps>) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: keyof TableData) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            sx={{ pointerEvents: 'none' }}
            icon={<StarIcon />}
          />
        </TableCell>
        {headCells?.map(headCell => (
          <TableCell
            key={headCell.id}
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function EntityTable({
  entities,
  totalItems,
  headCells,
}: Readonly<EntityTableProps>) {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TableData>('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<any>([]);
  const { toggleStarredEntity, isStarredEntity } = useStarredEntities();

  useEffect(() => {
    const formattedEntities = entities?.map((item: Entity) => ({
      kind: item?.kind ?? 'Component',
      namespace: item?.metadata.namespace ?? 'default',
      name: item?.metadata.name ?? '',
      description: item?.metadata.description ?? '',
      tags: item.metadata.tags ?? [],
      title: item.metadata.title ?? '',
      owner: item.spec?.owner ?? '',
      targets: item.spec?.targets ?? '',
      type: item.spec?.type ?? '',
    }));

    setRows(formattedEntities);
  }, [entities, setRows]);

  const handleRequestSort = (
    _: MouseEvent<unknown>,
    property: keyof TableData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const pageSizeSelection = parseInt(event.target.value, 10);
    setRowsPerPage(pageSizeSelection);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function getViewMessage(numberRows: number, numberTotalItems: number) {
    // Ensure rowsPerPage is not greater than totalItems
    const validRowsPerPage = Math.min(numberRows, numberTotalItems);
    return `Viewing ${validRowsPerPage} of ${totalItems} results`;
  }

  return (
    <>
      <Box sx={{ marginBottom: '16px' }}>
        {getViewMessage(rowsPerPage, totalItems)}
      </Box>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750, tableLayout: 'fixed' }}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {rows
                  ?.sort(getComparator(order, orderBy))
                  .slice()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: number) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.name}
                        sx={{ height: 'auto' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            icon={<StarBorderIcon />}
                            checkedIcon={<StarIcon />}
                            color="primary"
                            checked={isStarredEntity(row)}
                            onChange={() => toggleStarredEntity(row)}
                          />
                        </TableCell>
                        {headCells.some(headCell => headCell.id === 'name') && (
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <EntityRefLink
                              entityRef={{
                                kind: row.kind,
                                namespace: row.namespace,
                                name: row.name,
                              }}
                            />
                          </TableCell>
                        )}
                        {headCells.some(headCell => headCell.id === 'owner') &&
                          row.owner && (
                            <TableCell sx={{ paddingLeft: '0' }}>
                              <EntityRefLink
                                entityRef={{
                                  kind: 'group',
                                  namespace: 'default',
                                  name: row.owner,
                                }}
                              />
                            </TableCell>
                          )}
                        {headCells.some(headCell => headCell.id === 'type') && (
                          <TableCell sx={{ paddingLeft: '0' }}>
                            {row.type}
                          </TableCell>
                        )}
                        {headCells.some(
                          headCell => headCell.id === 'description',
                        ) && (
                          <TableCell
                            sx={{
                              paddingLeft: '0',
                              whiteSpace: 'normal',
                              maxWidth: '50%',
                              wordBreak: 'break-word',
                            }}
                          >
                            {row.description}
                          </TableCell>
                        )}
                        {headCells.some(headCell => headCell.id === 'tags') && (
                          <TableCell sx={{ paddingLeft: '0' }}>
                            {row.tags?.map((tag: string) => (
                              <Chip key={tag} label={tag} variant="outlined" />
                            ))}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow sx={{ height: 'auto' }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={allowedRowsPerPage}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
