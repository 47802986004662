import { useQueryEntities } from './useQueryEntities';
import { useFacetsEntities } from '@mb.io/core-components';
import { useSelectedOwners } from './useSelectedOwners';

export function useFetchEntities({
  mode,
  initialSelectedOwnersRefs,
}: {
  mode: 'owners-only' | 'all';
  initialSelectedOwnersRefs: string[];
}) {
  const isOwnersOnlyMode = mode === 'owners-only';
  const queryEntitiesResponse = useQueryEntities();
  const facetsEntitiesResponse = useFacetsEntities({
    enabled: isOwnersOnlyMode,
  });

  const [state, handleFetch] = isOwnersOnlyMode
    ? facetsEntitiesResponse
    : queryEntitiesResponse;

  return [
    state,
    handleFetch,
    useSelectedOwners({
      enabled: !isOwnersOnlyMode,
      initialSelectedOwnersRefs,
    }),
  ] as const;
}
