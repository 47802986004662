import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/esm/useAsync';

export function useAllKinds(): {
  loading: boolean;
  error?: Error;
  allKinds: string[];
} {
  const catalogApi = useApi(catalogApiRef);

  const {
    error,
    loading,
    value: allKinds,
  } = useAsync(async () => {
    return await catalogApi
      .getEntityFacets({ facets: ['kind'] })
      .then(
        response =>
          response.facets.kind
            ?.map(f => f.value)
            .sort((a, b) => a.localeCompare(b)) || [],
      );
  }, [catalogApi]);

  return { loading, error, allKinds: allKinds ?? [] };
}
