import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useState } from 'react';
import { Entity, parseEntityRef } from '@backstage/catalog-model';
import useAsyncFn from 'react-use/esm/useAsyncFn';
import {
  FacetsCursor,
  FacetsEntitiesResponse,
  FacetsInitialRequest,
} from '../types';

function decodeCursor(
  request: FacetsInitialRequest | FacetsEntitiesResponse,
): FacetsCursor {
  if (isFacetsResponse(request) && request.cursor) {
    return JSON.parse(atob(request.cursor));
  }
  return {
    text: (request as FacetsInitialRequest).text || '',
    start: 0,
  };
}

function isFacetsResponse(
  request: FacetsInitialRequest | FacetsEntitiesResponse,
): request is FacetsEntitiesResponse {
  return !!(request as FacetsEntitiesResponse).cursor;
}

function encodeCursor({
  entities,
  limit,
  payload,
}: {
  entities: Entity[];
  limit: number;
  payload: { text: string; start: number };
}) {
  if (entities.length > limit) {
    return { cursor: btoa(JSON.stringify(payload)) };
  }
  return {};
}

function filterEntity(text: string, entity: Entity) {
  const normalizedText = text.trim();
  return (
    entity.kind.includes(normalizedText) ||
    entity.metadata.namespace?.includes(normalizedText) ||
    entity.metadata.name.includes(normalizedText)
  );
}

export function useFacetsEntities({ enabled }: { enabled: boolean }) {
  const catalogApi = useApi(catalogApiRef);

  const [facetsPromise] = useState(async () => {
    if (!enabled) {
      return [];
    }
    const facet = 'relations.ownedBy';

    return catalogApi
      .getEntityFacets({ facets: [facet] })
      .then(response =>
        response.facets[facet]
          .map(e => e.value)
          .map(ref => {
            const { kind, name, namespace } = parseEntityRef(ref);
            return {
              apiVersion: 'backstage.io/v1beta1',
              kind,
              metadata: { name, namespace },
            };
          })
          .sort(
            (a, b) =>
              a.kind.localeCompare(b.kind, 'en-US') ||
              a.metadata.namespace.localeCompare(
                b.metadata.namespace,
                'en-US',
              ) ||
              a.metadata.name.localeCompare(b.metadata.name, 'en-US'),
          ),
      )
      .catch(() => []);
  });

  return useAsyncFn<
    (
      request: FacetsInitialRequest | FacetsEntitiesResponse,
      options?: { limit?: number },
    ) => Promise<FacetsEntitiesResponse>
  >(
    async (request, options) => {
      const facets = await facetsPromise;

      if (!facets) {
        return {
          items: [],
        };
      }

      const limit = options?.limit ?? 20;

      const { text, start } = decodeCursor(request);
      const filteredRefs = facets.filter(e => filterEntity(text, e));
      const end = start + limit;
      return {
        items: filteredRefs.slice(0, end),
        ...encodeCursor({
          entities: filteredRefs,
          limit: end,
          payload: {
            text,
            start: end,
          },
        }),
      };
    },
    [facetsPromise],
    { loading: true, value: { items: [] } },
  );
}
