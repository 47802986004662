import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { userSettingsApiRef } from '@mercedes-benz/user-settings-react';
import { useApi } from '@backstage/core-plugin-api';
import {
  FEATURE_USER_ENABLED_FEATURES,
  USER_PREFERENCES,
} from '@mercedes-benz/user-settings-common';

// no need to store all other fields in this case.
export type UserFeatureFlagType = Record<string, boolean | undefined>;

type UserFeatureFlagContextType = {
  features: UserFeatureFlagType;
  initialize: (features: UserFeatureFlagType) => void;
  update: (feature: string, enabled: boolean) => void;
};

const readInitialFeatures = async (
  userSettingsApi: any,
): Promise<UserFeatureFlagType> => {
  const item = await userSettingsApi.get({
    bucket: USER_PREFERENCES,
    key: FEATURE_USER_ENABLED_FEATURES,
  });
  if (!item?.value) {
    return {};
  }
  return item.value;
};

const UserFeatureFlagContext = createContext<
  UserFeatureFlagContextType | undefined
>(undefined);

export const UserFeatureFlagContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const userSettingsApi = useApi(userSettingsApiRef);
  const [features, setFeatures] = useState<UserFeatureFlagType>({});

  useEffect(() => {
    const fetchInitialFeatures = async () => {
      const initialFeatures = await readInitialFeatures(userSettingsApi);
      setFeatures(initialFeatures);
    };

    fetchInitialFeatures();
  }, [userSettingsApi]);

  const value: UserFeatureFlagContextType = useMemo(
    () => ({
      features,
      initialize: async (initialFeatures: UserFeatureFlagType) => {
        const storedFeatures = await readInitialFeatures(userSettingsApi);
        const initialFeatureKeys = Object.keys(initialFeatures);
        const storedFeatureKeys = Object.keys(storedFeatures);

        const keysAreDifferent =
          initialFeatureKeys.length !== storedFeatureKeys.length ||
          !initialFeatureKeys.every(key => storedFeatureKeys.includes(key));

        if (keysAreDifferent) {
          await userSettingsApi.set({
            bucket: USER_PREFERENCES,
            key: FEATURE_USER_ENABLED_FEATURES,
            value: initialFeatures,
          });
          setFeatures(initialFeatures);
        } else {
          setFeatures(storedFeatures);
        }
      },
      update: async (feature: string, enabled: boolean) => {
        const updatedFeatures = { ...features, [feature]: enabled };
        await userSettingsApi.set({
          bucket: USER_PREFERENCES,
          key: FEATURE_USER_ENABLED_FEATURES,
          value: updatedFeatures,
        });
        setFeatures(updatedFeatures);
      },
    }),
    [features, setFeatures, userSettingsApi],
  );

  return (
    <UserFeatureFlagContext.Provider value={value}>
      {children}
    </UserFeatureFlagContext.Provider>
  );
};

export function useUserFeatureFlagContext(): UserFeatureFlagContextType {
  const context = useContext(UserFeatureFlagContext);
  if (!context) {
    throw new Error(
      'useUserFeatureFlagContext must be used within GlobalFlagContextType, use <UserFeatureFlagContext /> before calling this hook',
    );
  }
  return context;
}
