import React, { useEffect, useState } from 'react';
import { Content, Header, HeaderLabel, Page } from '@backstage/core-components';
import { loadScripts, loadStylesheets, sanitizeHtml } from '../../utils/misc';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useAcademyEntity } from '../../hooks/useAcademyEntity';
import LinearProgress from '@mui/material/LinearProgress';
import { CodelabOverlays } from './CodelabOverlays';
import { CustomSnackbar } from '../CustomSnackbar/CustomSnackbar';
import { useTutorialProgress } from '../../hooks/useTutorialProgress';

const lightModeStyles = {
  border: '1px solid rgba(255, 255, 255, 0.5)',
  borderRadius: '0px',
  background: 'transparent',
  color: 'rgb(26, 26, 26)',
  baseBackgroundColor: 'rgb(255, 255, 255)',
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundAlt: 'rgb(198,198,198)',
  colorAlt: 'rgb(26, 26, 26)',
};

const darkModeStyles = {
  border: '1px solid rgba(255, 255, 255, 0.5)',
  borderRadius: '0px',
  background: 'transparent',
  color: 'rgb(255, 255, 255)',
  baseBackgroundColor: 'rgb(0, 0, 0)',
  backgroundColor: 'rgb(26, 26, 26)',
  backgroundAlt: 'rgb(59, 59, 59)',
  colorAlt: 'rgb(255, 255, 255)',
};

const useStyles = makeStyles(theme => {
  const modeStyles =
    theme.palette.mode === 'light' ? lightModeStyles : darkModeStyles;

  return {
    codelabs: {
      position: 'relative',
      height: '100%',
      '& #previous-step': {
        border: modeStyles.border,
        borderRadius: modeStyles.borderRadius,
        background: modeStyles.background,
        color: modeStyles.color,
      },
      '& #next-step': {
        borderRadius: modeStyles.borderRadius,
      },
      '& #done': {
        borderRadius: modeStyles.borderRadius,
      },
      '& #codelab-title': {
        position: 'absolute',
        background: modeStyles.backgroundColor,
        color: modeStyles.color,
      },
      '& google-codelab': {
        '& .steps': {
          backgroundColor: modeStyles.baseBackgroundColor,
        },
        '& .metadata': {
          backgroundColor: modeStyles.baseBackgroundColor,
        },
        '& #codelab-nav-buttons #arrow-back': {
          color: modeStyles.color,
        },
        '& #main': {
          background: modeStyles.baseBackgroundColor,
        },
        '& #steps': {
          maxHeight: '90%',
          background: modeStyles.backgroundColor,
        },
        '& #controls': {
          background: modeStyles.baseBackgroundColor,
          paddingTop: '16px',
        },
        '& #drawer ol': {
          '& li[selected] a:focus': {
            background: modeStyles.backgroundAlt,
          },
          '& li[completed] a': {
            color: modeStyles.colorAlt,
            background: modeStyles.baseBackgroundColor,
          },
          '& li a': {
            background: modeStyles.backgroundColor,
            color: modeStyles.color,
          },
        },
      },
      '& google-codelab-step': {
        background: `${modeStyles.baseBackgroundColor} !important`,
      },
      '& google-codelab-step .instructions': {
        maxWidth: '1200px !important',
        margin: '0 auto 90px !important',
        borderRadius: '4px !important',
        background: `${modeStyles.backgroundColor} !important`,
        '& :not(pre) > code': {
          background: modeStyles.backgroundAlt,
        },
      },
    },
  };
});

const stylesheetUrls = [
  '/academy/material-icons.css?v=1.0.0',
  '/academy/codelab-elements.css?v=1.0.0',
  'https://fonts.googleapis.com/css2?family=Material+Icons', // Added this as loading font-face from /academy/material-icons.woff2 is slow
];

const scripts = ['codelab-elements.js', 'native-shim.js', 'prettify.js'];

function addDataTestIds(htmlFile: Document): Document {
  const previousStep = htmlFile.querySelector('a#previous-step');
  if (previousStep) {
    previousStep.setAttribute('data-testid', 'previous-step-button');
  }

  const nextStepLink = htmlFile.querySelector('a#next-step');
  if (nextStepLink) {
    nextStepLink.setAttribute('data-testid', 'next-step-button');
  }

  return htmlFile;
}

async function renderCodelab(url: string) {
  let htmlString = await (await fetch(url)).text();

  // Parse the HTML string to a DOM object
  const parser = new DOMParser();
  const htmlFile = parser.parseFromString(htmlString, 'text/html');

  const htmlFileWithTestIds = addDataTestIds(htmlFile);

  const serializer = new XMLSerializer();
  htmlString = serializer.serializeToString(htmlFileWithTestIds);

  const academyEntity = htmlString.match(
    /<google-codelab codelab-gaid=".*?">(.*?)<\/google-codelab>/gs,
  );
  if (!academyEntity || academyEntity.length === 0) {
    throw new Error('No codelab found');
  }

  return sanitizeHtml(academyEntity[0]);
}

type CodelabProps = {
  namespaceId: string;
  tutorialName: string;
  showHeader?: boolean;
};

export function Codelab({
  namespaceId,
  tutorialName,
  showHeader = true,
}: Readonly<CodelabProps>) {
  const [loading, setLoading] = useState(false);
  const [codelabHtml, setCodelabHtml] = useState<undefined | string>(undefined);
  const theme = useTheme();
  const classes = useStyles(theme);
  const { entity, imgUrl, indexUrl, academyBaseUrl } = useAcademyEntity(
    namespaceId,
    tutorialName,
  );
  const { progress, isNotificationOpen, setIsNotificationOpen } =
    useTutorialProgress(entity);

  useEffect(() => {
    if (!entity || !imgUrl || !indexUrl) {
      return;
    }

    setLoading(true);

    renderCodelab(indexUrl)
      .then(htmlString => {
        // Replace image relative path to Academy content API image url
        const finalHtmlString = htmlString.replace(/img\//g, imgUrl);

        setCodelabHtml(finalHtmlString);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        throw new Error(err);
      });
  }, [entity, imgUrl, indexUrl]);

  useEffect(() => {
    let scriptUrls: string[];

    if (academyBaseUrl) {
      scriptUrls = scripts.map(
        scriptName =>
          `${academyBaseUrl}/content/static/academy_src/${scriptName}`,
      );
      loadScripts(scriptUrls);
      loadStylesheets(stylesheetUrls);
    }

    // Cleanup when unmounting
    return () => {
      stylesheetUrls.forEach(stylesheetUrl => {
        const linkElement = document.querySelector(
          `link[href="${stylesheetUrl}"]`,
        );
        if (linkElement) {
          document.head.removeChild(linkElement);
        }
      });

      scriptUrls?.forEach(scriptUrl => {
        const scriptElement = document.querySelector(
          `script[src="${scriptUrl}"]`,
        );
        if (scriptElement) {
          document.body.removeChild(scriptElement);
        }
      });
    };
  }, [academyBaseUrl]);

  const handleClose = () => {
    setIsNotificationOpen(false);
  };

  return (
    <Page themeId="tool">
      {showHeader && (
        <Header
          title="Academy"
          subtitle="Let's Learn Something New: Unlock Your Full Potential"
        >
          <HeaderLabel label="Owner" value="Nebula" />
          <HeaderLabel label="Lifecycle" value="Alpha" />
        </Header>
      )}
      <Content>
        {loading && <LinearProgress />}
        {entity && <CodelabOverlays entity={entity} />}
        <CustomSnackbar
          open={!progress && isNotificationOpen}
          message="The tutorial progress will be synced to your user settings. You can close the tab or browser and reopen this tutorial later to continue from where you left off."
          onClose={handleClose}
        />
        <CustomSnackbar
          open={progress === 'continue' && isNotificationOpen}
          message="The tutorial has been synced from your user settings. You can now continue from where you left off last time."
          onClose={handleClose}
        />
        <CustomSnackbar
          open={progress === 'restart' && isNotificationOpen}
          message="You have completed this tutorial once. You may start from the beginning."
          onClose={handleClose}
        />
        {codelabHtml && (
          <div
            className={classes.codelabs}
            dangerouslySetInnerHTML={{ __html: codelabHtml as any }}
          />
        )}
      </Content>
    </Page>
  );
}
