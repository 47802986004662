import { useApi } from '@backstage/core-plugin-api';
import { userSettingsApiRef } from '@mercedes-benz/user-settings-react';
import { useEffect, useState } from 'react';
import {
  FEATURE_ACADEMY_PROGRESS,
  USER_PREFERENCES,
} from '@mercedes-benz/user-settings-common';
import { AcademyEntity } from '@mb.io/catalog-model';
import { useNavigate } from 'react-router-dom';

export function useTutorialProgress(entity: AcademyEntity | undefined) {
  const navigate = useNavigate();
  const userSettingsApi = useApi(userSettingsApiRef);
  const [progress, setProgress] = useState('');
  const [isNotificationOpen, setIsNotificationOpen] = useState(true);

  useEffect(() => {
    async function getUserSettings(academyEntity: any) {
      const uniqueKey = `${FEATURE_ACADEMY_PROGRESS}_${academyEntity.metadata.name?.replace(
        / /g,
        '_',
      )}`;

      const settings = await userSettingsApi.get({
        bucket: USER_PREFERENCES,
        key: uniqueKey,
      });

      const hash = window.location.hash;

      if (settings?.value && !hash && settings?.value !== 'done') {
        setProgress('continue');
        return settings?.value;
      }

      if (settings?.value === 'done' && !hash) {
        setProgress('restart');
        return '#0';
      }

      return hash;
    }

    const redirectToStep = async () => {
      if (entity) {
        // erase what is in localstorage
        if (
          entity?.spec?.codelab &&
          typeof entity?.spec?.codelab === 'object'
        ) {
          const key = `progress_${entity?.spec?.codelab?.id}`;
          localStorage.removeItem(key);
        }

        const stepValue = await getUserSettings(entity);

        if (stepValue && typeof stepValue === 'string') {
          navigate(`${stepValue}`);
        }
      }
    };

    redirectToStep();
  }, [navigate, entity, userSettingsApi]);

  return { progress, isNotificationOpen, setIsNotificationOpen };
}
