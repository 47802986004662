import React, { useEffect } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import {
  SearchFilterComponentProps,
  useSearch,
} from '@backstage/plugin-search-react';
import { makeStyles } from 'tss-react/mui';
import CheckIcon from '@mui/icons-material/Check';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles()(theme => ({
  label: {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
  },
  toggleButton: {
    marginLeft: 8,
    marginRight: 8,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

type ToggleFilterProps = SearchFilterComponentProps & {
  placement: 'modal' | 'page';
};

export const ToggleFilter = (props: ToggleFilterProps) => {
  const { className, defaultValue, label, name, placement } = props;
  const { classes } = useStyles();
  const { filters, setFilters, setPageCursor } = useSearch();

  useEffect(() => {
    if (defaultValue && [defaultValue].flat().length > 0) {
      setFilters(prevFilters => ({
        ...prevFilters,
        [name]: defaultValue,
      }));
    }
  }, [defaultValue, name, setFilters]);

  // Determine if the filter is active
  const isActive = Boolean(filters[name]);

  const handleToggle = () => {
    setFilters(prevFilters => {
      const { [name]: _, ...remainingFilters } = prevFilters;
      return isActive
        ? remainingFilters
        : { ...remainingFilters, [name]: true };
    });
    setPageCursor(undefined);
  };

  return (
    <FormControl
      className={className}
      fullWidth
      data-testid="search-togglefilter-next"
    >
      <FormControlLabel
        labelPlacement={placement === 'modal' ? 'start' : 'end'}
        control={
          <ToggleButton
            className={classes.toggleButton}
            selected={isActive}
            onClick={handleToggle}
            value="check"
          >
            <CheckIcon />
          </ToggleButton>
        }
        label={label}
      />
    </FormControl>
  );
};
