import React, { useEffect } from 'react';
import { AcademyEntity } from '@mb.io/catalog-model';
import { useProgressEventListeners } from '../../hooks/useProgressEventListeners';

type CodelabOverlaysProps = {
  entity: AcademyEntity;
};

export function CodelabOverlays(props: CodelabOverlaysProps) {
  const { trackUserProgress } = useProgressEventListeners(props.entity);

  useEffect(() => {
    function addDataTestIds() {
      const previousButton = document.getElementById('previous-step');
      if (previousButton) {
        previousButton.setAttribute('data-testid', 'previous-step-button');
      }

      const nextButton = document.getElementById('next-step');
      if (nextButton) {
        nextButton.setAttribute('data-testid', 'next-step-button');
      }

      const steps = document.querySelector('div.steps');
      steps?.setAttribute('data-testid', 'steps');

      return addStepTitleTestIds();
    }

    function addStepTitleTestIds() {
      const stepTitles = document.querySelectorAll('.step');
      stepTitles.forEach(step => {
        const span = step.querySelector('span');
        if (span) {
          span.setAttribute('data-testid', 'step-title');
        }
      });
    }

    const handleCodelabReady = () => {
      // Set url for the Academy home page
      const doneButton = document.getElementById('done');
      if (doneButton) {
        doneButton.setAttribute('data-testid', 'done-button');
        doneButton.addEventListener('click', event => {
          event.preventDefault();
          window.history.back();
        });
      }

      const goBackButton = document.getElementById('arrow-back');
      if (goBackButton) {
        goBackButton.setAttribute('data-testid', 'close-button');
        goBackButton.addEventListener('click', event => {
          event.preventDefault();
          window.history.back();
        });
      }

      // Only compute if the entity is already loaded
      const feedbackButton = document.getElementById('codelab-feedback');
      if (feedbackButton) {
        feedbackButton.addEventListener('click', event => {
          event.preventDefault();
          window.open(
            (props.entity as any).spec.codelab?.feedbackLink,
            '_blank',
            'noopener,noreferrer',
          );
        });
      }

      addDataTestIds();
      trackUserProgress();
    };

    document.addEventListener('google-codelab-ready', handleCodelabReady);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('google-codelab-ready', handleCodelabReady);
    };
  }, [props.entity, trackUserProgress]);

  return <></>;
}
