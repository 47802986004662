import { createApiRef } from '@backstage/core-plugin-api';
import { WebHook, WebHookResponse } from './types';

export const dwsApiRef = createApiRef<DwsApi>({
  id: 'plugin.dws.api',
});

export interface DwsApi {
  getAllWebHooks(): Promise<WebHookResponse[]>;

  getAllUnfilteredWebHooks(): Promise<string[]>;

  createNewWebHook(webHook: WebHook): Promise<Response>;

  deleteWebHook(name: string): Promise<void>;

  updateWebHook(webHook: WebHook, webHookNameId: string): Promise<Response>;

  getAllTenantsByUser(): Promise<string[]>;
}
