import React, { useCallback } from 'react';
import { SidebarItem } from '@backstage/core-components';
import { IconComponent } from '@backstage/core-plugin-api';
import {
  SearchModal,
  SearchModalProps,
  SearchModalProvider,
  useSearchModal,
} from '@backstage/plugin-search';
import SearchIcon from '@mui/icons-material/Search';

/**
 * Props for {@link SidebarSearchModal}.
 *
 * @public
 */
export type SidebarSearchModalProps = Pick<
  SearchModalProps,
  'children' | 'resultItemComponents'
> & {
  icon?: IconComponent;
};

const SidebarSearchModalContent = (props: SidebarSearchModalProps) => {
  const { state, toggleModal } = useSearchModal();
  const Icon = props.icon ? props.icon : SearchIcon;

  const openSearch = useCallback(() => {
    toggleModal();
    document.body.style.overflow = 'hidden';
  }, [toggleModal]);

  const closeSearch = useCallback(() => {
    toggleModal();
    document.body.style.overflow = 'auto';
  }, [toggleModal]);

  return (
    <>
      <SidebarItem
        className="search-icon"
        icon={Icon}
        text="Search"
        onClick={openSearch}
      />
      <SearchModal
        {...state}
        toggleModal={closeSearch}
        resultItemComponents={props.resultItemComponents}
        children={props.children}
      />
    </>
  );
};

export const SidebarSearchModal = (props: SidebarSearchModalProps) => {
  return (
    <SearchModalProvider>
      <SidebarSearchModalContent {...props} />
    </SearchModalProvider>
  );
};
