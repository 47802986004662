import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

type SnackbarProps = {
  open: boolean;
  message: string;
  onClose: () => void;
  autoHideDuration?: number;
};

export const CustomSnackbar: React.FC<SnackbarProps> = ({
  open,
  message,
  onClose,
  autoHideDuration = 6000,
}) => {
  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={onClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <SnackbarContent
        action={action}
        sx={{
          backgroundColor: 'rgb(0,120,214)',
          color: 'white',
        }}
        message={
          <Box
            sx={{
              width: '532px',
              fontSize: '14px',
              paddingLeft: '22px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InfoIcon sx={{ marginRight: '16px' }} />
            <span>{message}</span>
          </Box>
        }
      />
    </Snackbar>
  );
};
